/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html, body, #root, .App {
  background: #205341;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Space Mono', monospace;
  text-align: center;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

p {
  text-align: center;
}

button {
  font-family: 'Space Mono', monospace;
  border: none;
  border-radius: 50px;
  background: #ffa9d3;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.7rem 2.6rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2rem;
  transition: background-color 0.15s ease-in-out, transform 0.2s ease-in-out;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.2);
  color: rgb(153, 100, 103);
  font-weight: bold;
  cursor: pointer;
}

button:active {
  transform: translateY(2px);
  background: #ffbede;
}

.pure-css-select-style {
  margin-bottom: 12px;
  border-radius: 4px;
}

label {
  margin-bottom: 6px;
  display: block;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 30px 40px 30px 40px;
}

.App-start-page h1 {
  margin-top: 80px;
  margin-bottom: 8px;
}

.App-start-page h3 {
  margin-top: 8px;
  margin-bottom: 100px;
  font-size: 17px;
  font-style: italic;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.App-start-page p {
  font-size: 14px;
}

.aito-powered {
  position: fixed;
  bottom: 15px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.aito-powered h4 {
  position: relative;
  top: -3px;
  margin: 0;
  font-size: 14px;
}

.aito-powered img {
  margin-left: 10px;
}

.aito-logo {
  height: 24px;
}

.App-preferences-page .form-container {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}

.App-preferences-page h2 {
  margin-bottom: 40px;
}

.App-preferences-page .curly-label {
  margin-top: 30px;
  margin-bottom: 20px;
}

.App-preferences-page .slider-container {
  padding: 0 10px;
  margin-bottom: 40px;
}

.App-preferences-page .rc-slider-mark .rc-slider-mark-text:nth-child(1) {
  left: 8%;
}

.App-preferences-page .rc-slider-mark .rc-slider-mark-text:nth-child(2) {
  left: 90%;
}

.App-preferences-page .rc-slider-handle {
  margin-left: -10px;
  margin-top: -8px;
  width: 20px;
  height: 20px;
}


.App-recommendation-page h2 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.App-recommendation-page h3 {
  margin: 0;
  color: #64B99A;
  margin-bottom: 25px;
}

.App-recommendation-page h4 {
  margin: 0;
  margin-top: 8px;
  margin-bottom: 25px;
  font-size: 13px;
  color: #64B99A;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.App-recommendation-page img {
  max-height: 300px;
}

.App-recommendation-page p {
  margin: 0;
  padding: 0 10px 0 10px;
  font-style: italic;
  font-size: 14px;
  width: 100%;
}

.App-recommendation-page .author {
  margin-top: 8px;
  margin-bottom: 30px;
}


.App-make-page ol {
  width: 100%;
  font-size: 18px;
  text-align: left;
  margin-bottom: 60px;
  max-width: 300px;
}

.App-make-page p {
  width: 100%;
  text-align: left;
}